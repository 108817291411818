import type { Setting } from "~/types/trade.js";

export const useSettings = () => {
  const settingsState = useState<Setting | null>("settings", () => null); // Initialize with null or an appropriate default value

  if (settingsState.value !== null) {
    return settingsState;
  }
  onMounted(async () => {
    const headers = await useApiHeaders();
    const { data } = await $fetch("/api/settings", {
      ...headers,
    });
    settingsState.value = data; // Assuming `data` is the structure returned by `useFetch`
  });

  return settingsState;
};
